<template>
<div>
<app-header />
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <!-- <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary" style="margin-top: 3px;">
            Hustleforge
          </h2>
        </b-link> -->

        <b-card-title class="mb-1">
          Looking for answers or want to leverage your expertise?
        </b-card-title>
        <b-card-text class="mb-2">
          Join now and start making connections!
        </b-card-text>

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="register"
          >
            <!-- fullname -->
            <b-form-group
              label="Full Name"
              label-for="Fullname"
            >
              <validation-provider
                #default="{ errors }"
                name="full name"
                rules="required"
              >
                <b-form-input
                  id="fullname"
                  v-model="fullname"
                  :state="errors.length > 0 ? false:null"
                  name="register-fullname"
                  placeholder="john doe"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- username -->
            <b-form-group
              label="Username"
              label-for="Username"
            >
              <validation-provider
                #default="{ errors }"
                name="username"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="username"
                  :state="errors.length > 0 ? false:null"
                  name="register-username"
                  placeholder="john-doe"
                />
                <small class="text-primary">*Cannot have spaces or special characters</small><br/>
                <small class="text-primary">*Cannot be changed after account is created</small><br/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="regEmail"
                  :state="errors.length > 0 ? false:null"
                  name="register-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
              label="Password"
              label-for="password"
            >
            
              <validation-provider
                #default="{ errors }"
                name="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- code -->
            <!-- <b-form-group
              label="Waitlist Code"
              label-for="code"
            >
              <validation-provider
                #default="{ errors }"
                name="waitlist code"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="code"
                    v-model="code"
                    :state="errors.length > 0 ? false:null"
                    name="code"
                    placeholder="1234"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->

            <!-- checkbox -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="privacy-policy"
                rules="required"
              >
              <b-form-checkbox
                id="register-privacy-policy"
                v-model="status"
                :state="errors.length > 0 ? false:null"
                name="checkbox-1"
              >
                I agree to
                <b-link class="pr-0.5" :to="{name:'privacy-policy'}">Privacy Policy</b-link> & 
                <b-link class="pl-0.5" :to="{name:'public-terms'}">Terms and Conditions</b-link>
              </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Sign up
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>Already have an account? </span>
          <b-link :to="{name:'auth-login'}">
            <span>Sign in instead</span>
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Register v1 -->
    </div>
  </div>
  <app-footer />
</div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import md5  from 'md5'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getAuth, createUserWithEmailAndPassword, updateProfile, sendEmailVerification} from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppFooter from '../landing/AppFooter.vue'
import AppHeader from '../landing/AppHeader.vue'

const auth = getAuth();
const db = getFirestore();

export default {
  components: {
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
    AppFooter,
    AppHeader,
    md5,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      regEmail: '',
      fullname: '',
      username: '',
      password: '',
      status: '',
      code: '',

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    async register() {
      // Validate form for to make sure data is correct
      const validateForm = await this.$refs.registerForm.validate()
      if (!validateForm) return

      // Validate waitlist code for Beta
      // if(md5(this.code) != '7c002e7655c8c3c3b556f3b88f6eef65'){
      //   this.$toast({
      //     component: ToastificationContent,
      //     position: 'top-right',
      //     props: {
      //       title: `Signup Failed`,
      //       icon: 'user-x',
      //       variant: 'danger',
      //       text: 'Waitlist code is invalid',
      //     },
      //   })
      //   return;
      // }
        
      // Check username to make sure it is unique
      const checkUserNames = await getDoc(doc(db, "usernames", this.username))
      if (checkUserNames.exists()){
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Signup Failed`,
              icon: 'user-x',
              variant: 'danger',
              text: 'Username already exists',
            },
          })
          return
      }

      // Check to make sure username doesnt have spaces 
      const specialChars = /[ `!@#$%^&*()_+\=\[\]{};':"\\|,<>\/?~]/;
      if(specialChars.test(this.username)){
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Signup Failed`,
              icon: 'user-x',
              variant: 'danger',
              text: 'Username has special characters or a space',
            },
          })
          return
      }

      // Register the new user 
      try {
        const response = await createUserWithEmailAndPassword(auth, this.regEmail, this.password)
        
        // Update display name in profile
        await updateProfile(response.user, { displayName: this.username.trim() })

        // Set username so they can't be used in the future and are unique
        await setDoc(doc(db, "usernames", this.username.trim() ),{ uid: response.user.uid })

        // Create the user
        const userData = {display_name: this.username.trim(), uid: response.user.uid, fullName: this.fullname, email: this.regEmail, image_url: null, phone_number: null, pushTokens: [], ability: null, created_time: serverTimestamp() };
        await setDoc(doc(db, "users", this.username.trim() ), userData)

        // Start creating the profile of the user
        const data = { username: this.username.trim(), uid: response.user.uid, fullName: this.fullname, rating:0, reviewCount: 0, contacts:0, responses:0, profileComplete: false, createdAt: serverTimestamp() }
        await setDoc(doc(db, "profiles", this.username.trim() ), data)

        // Send Email Verification
        sendEmailVerification(response.user)
        
        // Go to login page
        this.$router.push('/login').then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Verify your email`,
              icon: 'mail',
              variant: 'info',
              text: `Please verify your email before you can login into your account`,
              closeButton: false,
            },timeout: 30000
          })
        })
  
      }catch(error){
        console.log(error)
        let errorMessage = error;
        if(error.code) errorMessage = error.code.replace("auth/","").replaceAll("-", " ");

        // Show error
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Signup Failed`,
            icon: 'user-x',
            variant: 'danger',
            text: errorMessage,
          },timeout: 5000
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
